import { RouteConfig } from 'vue-router'

import AccuracyAppbar from '@/modules/accuracy/components/appbar/index.vue'
import CommentsAppbar from '@/modules/comment/components/appbar/index.vue'
import EditorHelpAppbar from '@/modules/editor/components/appbar/help.vue'
import OrganizationLandscapesAppbarList from '@/modules/organization/components/appbar/landscape-list.vue'
import OrganizationUpgrade from '@/modules/organization/components/appbar/upgrade.vue'
import RecommendationsAppbar from '@/modules/recommendation/components/appbar/index.vue'
import ShareAppbar from '@/modules/share/components/appbar/share.vue'
import UserAppbarAvatars from '@/modules/user/components/appbar/avatars.vue'

const meta = {
  background: '#323435',
  hasAppbar: true,
  hasDrawerLeft: true,
  requiresAuth: true,
  requiresSocket: true,
  title: 'Landscapes'
} as const

const appbar = {
  'appbar-left-1': OrganizationLandscapesAppbarList,
  'appbar-right-1': ShareAppbar,
  'appbar-right-2': UserAppbarAvatars,
  'appbar-right-3': EditorHelpAppbar,
  'appbar-right-4': AccuracyAppbar,
  'appbar-right-5': RecommendationsAppbar,
  'appbar-right-6': CommentsAppbar,
  'appbar-right-7': OrganizationUpgrade
}

export default [
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "overview" */ '@/modules/overview/views/overview.vue'),
        name: 'landscapes',
        path: 'landscapes'
      }
    ],
    components: {
      ...appbar,
      default: () => import(/* webpackChunkName: "landscape" */ './views/landscape.vue')
    },
    meta,
    name: 'organization',
    path: '/organizations/:organizationId',
    redirect: {
      name: 'landscapes'
    }
  },
  {
    components: {
      default: () => import(/* webpackChunkName: "landscape-setup" */ './views/landscape-setup.vue')
    },
    meta: {
      background: '#323435',
      hasAppbar: false,
      hasDrawerLeft: false,
      requiresAuth: true,
      requiresSocket: false,
      title: 'Landscapes'
    },
    name: 'landscape-setup',
    path: '/landscapes/setup'
  },
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "overview" */ '@/modules/overview/views/overview.vue'),
        meta: {
          title: 'Overview'
        },
        name: 'overview',
        path: 'overview'
      },
      {
        component: () => import(/* webpackChunkName: "diagrams" */ '@/modules/diagram/views/diagrams.vue'),
        meta: {
          title: 'Diagrams'
        },
        name: 'diagrams',
        path: 'diagrams'
      },
      {
        path: 'model',
        redirect: {
          name: 'model-objects'
        }
      },
      {
        component: () => import(/* webpackChunkName: "history" */ '@/modules/flow/views/flows.vue'),
        meta: {
          title: 'Flows'
        },
        name: 'flows',
        path: 'flows'
      },
      {
        component: () => import(/* webpackChunkName: "model-objects" */ '@/modules/model/views/objects.vue'),
        meta: {
          title: 'Objects'
        },
        name: 'model-objects',
        path: 'model/objects'
      },
      {
        component: () => import(/* webpackChunkName: "model-dependencies" */ '@/modules/model/views/dependencies.vue'),
        meta: {
          title: 'Dependencies'
        },
        name: 'model-dependencies',
        path: 'model/dependencies'
      },
      {
        component: () => import(/* webpackChunkName: "teams" */ '@/modules/team/views/team.vue'),
        meta: {
          title: 'Teams'
        },
        name: 'teams',
        path: 'teams'
      },
      {
        component: () => import(/* webpackChunkName: "history" */ '@/modules/history/views/history.vue'),
        meta: {
          title: 'History'
        },
        name: 'history',
        path: 'history'
      }
    ],
    components: {
      ...appbar,
      default: () => import(/* webpackChunkName: "landscape" */ './views/landscape.vue')
    },
    meta,
    name: 'landscape',
    path: '/landscapes/:landscapeId/versions/:versionId',
    redirect: {
      name: 'overview'
    }
  },
  {
    path: '/landscapes',
    redirect: {
      path: '/'
    }
  },
  {
    path: '/landscapes/:landscapeId',
    redirect: to => ({
      name: 'overview',
      params: {
        landscapeId: to.params.landscapeId,
        versionId: 'latest'
      }
    })
  },
  {
    path: '/landscapes/:landscapeId/versions',
    redirect: to => ({
      name: 'overview',
      params: {
        landscapeId: to.params.landscapeId,
        versionId: 'latest'
      }
    })
  },

  /**
   * Deprecated
   */

  {
    path: '/organizations/:organizationId/landscapes/:landscapeId',
    redirect: to => ({
      name: 'diagrams',
      params: {
        landscapeId: to.params.landscapeId,
        versionId: 'latest'
      }
    })
  },
  {
    path: '/landscapes/:landscapeId',
    redirect: to => ({
      name: 'diagrams',
      params: {
        landscapeId: to.params.landscapeId,
        versionId: 'latest'
      }
    })
  },
  {
    path: '/projects',
    redirect: {
      name: 'organizations'
    }
  }
] as RouteConfig[]
