
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import ContextMenuContent from '@/components/context-menu/content.vue'
import ContextMenu from '@/components/context-menu/index.vue'
import ContextMenuItem from '@/components/context-menu/item.vue'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'

@Component({
  components: {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    OrganizationUpgradeMenu
  },
  name: 'LandscapeImportMenu'
})
export default class extends Vue {
  @Prop() readonly activator?: () => HTMLElement
  @Prop() readonly landscapeLimitReached!: boolean
  @Ref() readonly menuRef!: ContextMenu
  @Ref() readonly importStructurizrButtonRef!: { $el: HTMLElement }
  @Ref() readonly importBackstageButtonRef!: { $el: HTMLElement }

  importFromStructurizr () {
    this.menuRef.close()
    if (!this.landscapeLimitReached) {
      this.$emit('close')
      this.$pushQuery({
        structurizr_import_dialog: '1'
      })
    }
  }

  importFromBackstage () {
    this.menuRef.close()
    if (!this.landscapeLimitReached) {
      this.$emit('close')
      this.$pushQuery({
        backstage_import_dialog: '1'
      })
    }
  }

  showImportFromIcePanelPage () {
    this.menuRef.close()
    this.$emit('close')
    window.open('https://docs.icepanel.io/features/landscape#moving-or-duplicating-a-landscape-to-another-organization', '_blank')
  }
}
