import * as analytics from '@icepanel/app-analytics'
import { TagColor, TagGroupIcon } from '@icepanel/platform-api-client'

export const landscapeId: analytics.Property<string[]> = {
  displayName: 'Landscape ID',
  name: 'landscape_id',
  required: true,
  type: 'array'
}
const organizationId: analytics.Property<string[]> = {
  displayName: 'Organization ID',
  name: 'organization_id',
  required: true,
  type: 'array'
}

const appUrl: analytics.Property<string> = {
  displayName: 'App URL',
  name: 'app_url',
  required: true,
  type: 'string'
}
const createdAt: analytics.Property<Date> = {
  displayName: 'Created at',
  name: '$created',
  required: true,
  type: 'date'
}
const name: analytics.Property<string> = {
  displayName: 'Name',
  name: '$name',
  required: true,
  type: 'string'
}
const versionCount: analytics.Property<number> = {
  displayName: 'Version count',
  name: 'version_count',
  required: true,
  type: 'number'
}
const versionRevertCount: analytics.Property<number> = {
  displayName: 'Version revert count',
  name: 'version_revert_count',
  required: true,
  type: 'number'
}

const commentQuestionOpenCount: analytics.Property<number> = {
  displayName: 'Comment question open count',
  name: 'comment_question_open_count',
  required: true,
  type: 'number'
}
const commentIdeaActiveCount: analytics.Property<number> = {
  displayName: 'Comment idea active count',
  name: 'comment_idea_active_count',
  required: true,
  type: 'number'
}
const commentInaccurateActiveCount: analytics.Property<number> = {
  displayName: 'Comment inaccurate open count',
  name: 'comment_inaccurate_open_count',
  required: true,
  type: 'number'
}

const modelObjectCount: analytics.Property<number> = {
  displayName: 'Model object count',
  name: 'model_object_count',
  required: true,
  type: 'number'
}
const modelObjectTagCount: analytics.Property<number> = {
  displayName: 'Model object tag count',
  name: 'model_object_tag_count',
  required: true,
  type: 'number'
}
const modelObjectLinksValidCount: analytics.Property<number> = {
  displayName: 'Model object links valid count',
  name: 'model_object_links_valid_count',
  required: true,
  type: 'number'
}
const modelObjectLinksInvalidCount: analytics.Property<number> = {
  displayName: 'Model object links invalid count',
  name: 'model_object_links_invalid_count',
  required: true,
  type: 'number'
}
const modelObjectLinksUnlinkedCount: analytics.Property<number> = {
  displayName: 'Model object links unlinked count',
  name: 'model_object_links_unlinked_count',
  required: true,
  type: 'number'
}
const modelObjectActorCount: analytics.Property<number> = {
  displayName: 'Model object actor count',
  name: 'model_object_actor_count',
  required: true,
  type: 'number'
}
const modelObjectAppCount: analytics.Property<number> = {
  displayName: 'Model object app count',
  name: 'model_object_app_count',
  required: true,
  type: 'number'
}
const modelObjectGroupCount: analytics.Property<number> = {
  displayName: 'Model object group count',
  name: 'model_object_group_count',
  required: true,
  type: 'number'
}
const modelObjectComponentCount: analytics.Property<number> = {
  displayName: 'Model object component count',
  name: 'model_object_component_count',
  required: true,
  type: 'number'
}
const modelObjectStoreCount: analytics.Property<number> = {
  displayName: 'Model object store count',
  name: 'model_object_store_count',
  required: true,
  type: 'number'
}
const modelObjectSystemCount: analytics.Property<number> = {
  displayName: 'Model object system count',
  name: 'model_object_system_count',
  required: true,
  type: 'number'
}

const modelConnectionCount: analytics.Property<number> = {
  displayName: 'Model connection count',
  name: 'model_connection_count',
  required: true,
  type: 'number'
}
const modelConnectionTagCount: analytics.Property<number> = {
  displayName: 'Model connection tag count',
  name: 'model_connection_tag_count',
  required: true,
  type: 'number'
}

const diagramCount: analytics.Property<number> = {
  displayName: 'Diagram count',
  name: 'diagram_count',
  required: true,
  type: 'number'
}
const contextDiagramCount: analytics.Property<number> = {
  displayName: 'Context diagram count',
  name: 'context_diagram_count',
  required: true,
  type: 'number'
}
const appDiagramCount: analytics.Property<number> = {
  displayName: 'App diagram count',
  name: 'app_diagram_count',
  required: true,
  type: 'number'
}
const componentDiagramCount: analytics.Property<number> = {
  displayName: 'Component diagram count',
  name: 'component_diagram_count',
  required: true,
  type: 'number'
}

const tagCount: analytics.Property<number> = {
  displayName: 'Tag count',
  name: 'tag_count',
  required: true,
  type: 'number'
}
const tagColors: analytics.Property<TagColor[]> = {
  displayName: 'Tag colors',
  name: 'tag_colors',
  required: true,
  type: 'array'
}

const tagGroupCount: analytics.Property<number> = {
  displayName: 'Tag group count',
  name: 'tag_group_count',
  required: true,
  type: 'number'
}
const tagGroupIcons: analytics.Property<TagGroupIcon[]> = {
  displayName: 'Tag group icons',
  name: 'tag_group_icons',
  required: true,
  type: 'array'
}

const flowCount: analytics.Property<number> = {
  displayName: 'Flow count',
  name: 'flow_count',
  required: true,
  type: 'number'
}
const flowStepCount: analytics.Property<number> = {
  displayName: 'Flow step count',
  name: 'flow_step_count',
  required: true,
  type: 'number'
}
const flowStepOutgoingCount: analytics.Property<number> = {
  displayName: 'Flow step outgoing count',
  name: 'flow_step_outgoing_count',
  required: true,
  type: 'number'
}
const flowStepReplyCount: analytics.Property<number> = {
  displayName: 'Flow step reply count',
  name: 'flow_step_reply_count',
  required: true,
  type: 'number'
}
const flowStepSelfActionCount: analytics.Property<number> = {
  displayName: 'Flow step self action count',
  name: 'flow_step_self_action_count',
  required: true,
  type: 'number'
}

const tourCountHidden: analytics.Property<number> = {
  displayName: 'Tour count',
  hidden: true,
  name: 'tour_count',
  required: true,
  type: 'number'
}
const tourStepCountHidden: analytics.Property<number> = {
  displayName: 'Tour step count',
  hidden: true,
  name: 'tour_step_count',
  required: true,
  type: 'number'
}
const tourStepDiagramCountHidden: analytics.Property<number> = {
  displayName: 'Tour step diagram count',
  hidden: true,
  name: 'tour_step_diagram_count',
  required: true,
  type: 'number'
}
const tourStepFlowCountHidden: analytics.Property<number> = {
  displayName: 'Tour step flow count',
  hidden: true,
  name: 'tour_step_flow_count',
  required: true,
  type: 'number'
}

export default new analytics.Group('landscape_id', {
  appDiagramCount,
  appUrl,
  commentIdeaActiveCount,
  commentInaccurateActiveCount,
  commentQuestionOpenCount,
  componentDiagramCount,
  contextDiagramCount,
  createdAt,
  diagramCount,
  flowCount,
  flowStepCount,
  flowStepOutgoingCount,
  flowStepReplyCount,
  flowStepSelfActionCount,
  modelConnectionCount,
  modelConnectionTagCount,
  modelObjectActorCount,
  modelObjectAppCount,
  modelObjectComponentCount,
  modelObjectCount,
  modelObjectGroupCount,
  modelObjectLinksInvalidCount,
  modelObjectLinksUnlinkedCount,
  modelObjectLinksValidCount,
  modelObjectStoreCount,
  modelObjectSystemCount,
  modelObjectTagCount,
  name,
  organizationId,
  tagColors,
  tagCount,
  tagGroupCount,
  tagGroupIcons,
  tourCountHidden,
  tourStepCountHidden,
  tourStepDiagramCountHidden,
  tourStepFlowCountHidden,
  versionCount,
  versionRevertCount
})
