import * as analytics from '@icepanel/app-analytics'

import { organizationId, originOrganizationId } from '@/modules/organization/helpers/analytics-group'

import { landscapeId } from './analytics-group'
import { landscapeName, landscapeOrganizationChanged, landscapeSetupChoice, landscapeSetupPageHidden, landscapeSetupStep, landscapeSetupVersion, landscapeTemplateId, landscapeTemplateName } from './analytics-properties'

/**
 * Screens
 */

export const landscapeScreen = new analytics.Event({
  displayName: 'Landscape screen',
  name: 'landscape_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})
export const landscapeEmptyScreen = new analytics.Event({
  displayName: 'Landscape empty screen',
  name: 'landscape_empty_screen',
  tags: [analytics.Tags.Screen]
}, {
  organizationId
})
export const landscapeSetupScreen = new analytics.Event({
  displayName: 'Landscape setup screen',
  name: 'landscape_setup_screen',
  tags: [analytics.Tags.Screen]
}, {
  organizationId
})

/**
 * Dialogs
 */

export const landscapeStructurizrImportDialog = new analytics.Event({
  displayName: 'Landscape Structurizr import dialog',
  name: 'landscape_structurizr_import_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId
})
export const landscapeBackstageImportDialog = new analytics.Event({
  displayName: 'Landscape Backstage import dialog',
  name: 'landscape_backstage_import_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId
})
export const landscapeCreateDialog = new analytics.Event({
  displayName: 'Landscape create dialog',
  name: 'landscape_create_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId: {
    ...organizationId,
    required: false
  }
})
export const landscapeDeleteDialog = new analytics.Event({
  displayName: 'Landscape delete dialog',
  name: 'landscape_delete_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId
})
export const landscapeCopyDialog = new analytics.Event({
  displayName: 'Landscape copy dialog',
  name: 'landscape_copy_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId
})
export const landscapeDuplicateDialog = new analytics.Event({
  displayName: 'Landscape copy dialog',
  name: 'landscape_copy_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId,
  originOrganizationId
})

/**
 * Events
 */

export const landscapeCreate = new analytics.Event({
  displayName: 'Landscape create',
  name: 'landscape_create'
}, {
  landscapeId,
  landscapeName,
  landscapeTemplateId,
  landscapeTemplateName,
  organizationId
})
export const landscapeUpdate = new analytics.Event({
  displayName: 'Landscape update',
  name: 'landscape_update'
}, {
  landscapeId,
  landscapeName: {
    ...landscapeName,
    required: false
  },
  organizationId
})
export const landscapeDelete = new analytics.Event({
  displayName: 'Landscape delete',
  name: 'landscape_delete'
}, {
  landscapeId,
  organizationId
})
export const landscapeDuplicate = new analytics.Event({
  displayName: 'Landscape duplicate',
  name: 'landscape_duplicate'
}, {
  landscapeId,
  landscapeName,
  landscapeOrganizationChanged,
  organizationId
})
export const landscapeCopy = new analytics.Event({
  displayName: 'Landscape copy',
  name: 'landscape_copy'
}, {
  landscapeId,
  landscapeName,
  organizationId
})
export const landscapeStructurizrImport = new analytics.Event({
  displayName: 'Landscape Structurizr import',
  name: 'landscape_structurizr_import'
}, {
  landscapeId,
  organizationId
})
export const landscapeBackstageImport = new analytics.Event({
  displayName: 'Landscape Backstage import',
  name: 'landscape_backstage_import'
}, {
  landscapeId,
  organizationId
})
export const landscapeSetupStepComplete = new analytics.Event({
  displayName: 'Landscape setup step complete',
  name: 'landscape_setup_step_complete'
}, {
  landscapeId,
  landscapeSetupChoice,
  landscapeSetupStep,
  landscapeSetupVersion,
  organizationId
})

/**
 * Hidden
 */

export const landscapeSetupVideoPlayHidden = new analytics.Event({
  displayName: 'Landscape setup video play',
  hidden: true,
  name: 'landscape_setup_video_play'
})
export const landscapeSetupVideoEndHidden = new analytics.Event({
  displayName: 'Landscape setup video end',
  hidden: true,
  name: 'landscape_setup_video_end'
})
export const landscapeTemplatesDialogHidden = new analytics.Event({
  displayName: 'Landscape templates dialog',
  hidden: true,
  name: 'landscape_templates_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId
})
export const landscapeAutocompleteTechChoicesDialogHidden = new analytics.Event({
  displayName: 'Landscape autocomplete tech choices dialog',
  hidden: true,
  name: 'landscape_autocomplete_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId,
  organizationId
})
export const landscapeSetupDialogHidden = new analytics.Event({
  displayName: 'Landscape setup dialog',
  hidden: true,
  name: 'landscape_setup_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeSetupPageHidden
})
export const landscapeSetupFormHidden = new analytics.Event({
  displayName: 'Landscape setup form',
  hidden: true,
  name: 'landscape_setup_form'
}, {
  landscapeSetupPageHidden
})
export const landscapeSetupFormCompleteHidden = new analytics.Event({
  displayName: 'Landscape setup form complete',
  hidden: true,
  name: 'landscape_setup_form_complete'
}, {
  landscapeSetupPageHidden
})
