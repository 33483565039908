import * as analytics from '@icepanel/app-analytics'

export type LandscapeSetupPage = 'core-system' | 'apps-store' | 'external-system'

export const landscapeName: analytics.Property<string> = {
  displayName: 'Landscape name',
  name: 'landscape_name',
  required: true,
  type: 'string'
}
export const landscapeTemplateId: analytics.Property<string | undefined> = {
  displayName: 'Landscape template ID',
  name: 'landscape_template_id',
  required: false,
  type: 'string'
}
export const landscapeTemplateName: analytics.Property<string | undefined> = {
  displayName: 'Landscape template name',
  name: 'landscape_template_name',
  required: false,
  type: 'string'
}
export const landscapeOrganizationChanged: analytics.Property<boolean> = {
  displayName: 'Landscape organization',
  name: 'landscape_organization_changed',
  required: true,
  type: 'boolean'
}
export const landscapeSetupStep: analytics.Property<number> = {
  displayName: 'Landscape setup step',
  name: 'landscape_setup_step',
  required: true,
  type: 'number'
}
export const landscapeSetupVersion: analytics.Property<number> = {
  displayName: 'Landscape setup version',
  name: 'landscape_setup_version',
  required: true,
  type: 'number'
}
export const landscapeSetupChoice: analytics.Property<string[] | undefined> = {
  displayName: 'Landscape setup choice',
  name: 'landscape_setup_choice',
  required: false,
  type: 'array'
}

/**
 * Hidden
 */

export const landscapeSetupPageHidden: analytics.Property<LandscapeSetupPage> = {
  displayName: 'Landscape setup page',
  hidden: true,
  name: 'landscape_setup_page',
  required: true,
  type: 'string'
}
