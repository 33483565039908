import * as fasArrowsAltH from '@fortawesome/free-solid-svg-icons/faArrowsAltH'
import * as fasBell from '@fortawesome/free-solid-svg-icons/faBell'
import * as fasBellSlash from '@fortawesome/free-solid-svg-icons/faBellSlash'
import * as fasBug from '@fortawesome/free-solid-svg-icons/faBug'
import * as fasCaretDown from '@fortawesome/free-solid-svg-icons/faCaretDown'
import * as fasCaretRight from '@fortawesome/free-solid-svg-icons/faCaretRight'
import * as fasChalkboardTeacher from '@fortawesome/free-solid-svg-icons/faChalkboardTeacher'
import * as fasCheckCircle from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import * as fasChevronRight from '@fortawesome/free-solid-svg-icons/faChevronRight'
import * as fasCircle from '@fortawesome/free-solid-svg-icons/faCircle'
import * as fasClock from '@fortawesome/free-solid-svg-icons/faClock'
import * as fasCloud from '@fortawesome/free-solid-svg-icons/faCloud'
import * as fasCodeBranch from '@fortawesome/free-solid-svg-icons/faCodeBranch'
import * as fasDatabase from '@fortawesome/free-solid-svg-icons/faDatabase'
import * as fasDiceOne from '@fortawesome/free-solid-svg-icons/faDiceOne'
import * as fasDiceTwo from '@fortawesome/free-solid-svg-icons/faDiceTwo'
import * as fasEllipsisH from '@fortawesome/free-solid-svg-icons/faEllipsisH'
import * as fasExchangeAlt from '@fortawesome/free-solid-svg-icons/faExchangeAlt'
import * as fasFileImport from '@fortawesome/free-solid-svg-icons/faFileImport'
import * as fasFileUpload from '@fortawesome/free-solid-svg-icons/faFileUpload'
import * as fasInfoCircle from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import * as fasLaptop from '@fortawesome/free-solid-svg-icons/faLaptop'
import * as fasMapMarkedAlt from '@fortawesome/free-solid-svg-icons/faMapMarkedAlt'
import * as fasPencilAlt from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import * as fasPercent from '@fortawesome/free-solid-svg-icons/faPercent'
import * as fasPlay from '@fortawesome/free-solid-svg-icons/faPlay'
import * as fasPlus from '@fortawesome/free-solid-svg-icons/faPlus'
import * as fasPlusCircle from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import * as fasSearch from '@fortawesome/free-solid-svg-icons/faSearch'
import * as fasSitemap from '@fortawesome/free-solid-svg-icons/faSitemap'
import * as fasTimes from '@fortawesome/free-solid-svg-icons/faTimes'
import * as fasTimesCircle from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import * as fasTrashAlt from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import * as fasUsers from '@fortawesome/free-solid-svg-icons/faUsers'
import * as fasUsersCog from '@fortawesome/free-solid-svg-icons/faUsersCog'

export default [
  fasArrowsAltH.definition,
  fasBell.definition,
  fasBellSlash.definition,
  fasBug.definition,
  fasCaretDown.definition,
  fasCaretRight.definition,
  fasChalkboardTeacher.definition,
  fasCheckCircle.definition,
  fasChevronRight.definition,
  fasCircle.definition,
  fasClock.definition,
  fasCloud.definition,
  fasCodeBranch.definition,
  fasDatabase.definition,
  fasDiceOne.definition,
  fasDiceTwo.definition,
  fasEllipsisH.definition,
  fasExchangeAlt.definition,
  fasFileImport.definition,
  fasFileUpload.definition,
  fasInfoCircle.definition,
  fasLaptop.definition,
  fasMapMarkedAlt.definition,
  fasPencilAlt.definition,
  fasPercent.definition,
  fasPlay.definition,
  fasPlus.definition,
  fasPlusCircle.definition,
  fasSearch.definition,
  fasSitemap.definition,
  fasTimes.definition,
  fasTimesCircle.definition,
  fasTrashAlt.definition,
  fasUsers.definition,
  fasUsersCog.definition
] as const
