
import { Landscape, LandscapesService } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import ContextMenuContent from '@/components/context-menu/content.vue'
import ContextMenu from '@/components/context-menu/index.vue'
import ContextMenuItem from '@/components/context-menu/item.vue'
import { LandscapeModule } from '@/modules/landscape/store'
import OrganizationAvatar from '@/modules/organization/components/dropdown/avatar.vue'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { RouteModule } from '@/modules/route/store'
import { UserModule } from '@/modules/user/store'

import ImportMenu from './import-menu.vue'
import LandscapeListItem from './landscape-list-item.vue'

@Component({
  components: {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ImportMenu,
    LandscapeListItem,
    OrganizationAvatar,
    OrganizationUpgradeMenu
  },
  name: 'LandscapeListItems'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  routeModule = getModule(RouteModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  @Ref() readonly listRef!: { $el: HTMLElement }
  @Ref() readonly listItemRefs!: { $el: HTMLElement }[]
  @Ref() readonly newLandscapeButtonRef!: { $el: HTMLElement }

  @Prop() organizationMenuId!: string

  selectedOrganizationId = this.organizationMenuId
  search = ''
  searchModel = ''
  loading = true

  landscapes: Landscape[] = []

  MAX_LANDSCAPE_VISIBLE_LINES = 10

  get searchedLandscapeIds () {
    if (this.search) {
      const fuse = new Fuse(this.landscapes, {
        keys: [
          'name'
        ],
        threshold: 0.3
      })
      return fuse
        .search(this.search)
        .map(o => [o.item.id])
        .flat()
    } else {
      return []
    }
  }

  get expandedItems () {
    const searchedLandscapeIds = this.searchedLandscapeIds

    return this.landscapes
      .filter(landscape => (
        this.selectedOrganizationId === landscape.organizationId &&
        (!this.search || searchedLandscapeIds.includes(landscape.id))
      ))
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  get selectedOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.selectedOrganization)
  }

  get landscapeLimitReached () {
    return this.landscapes.length >= this.selectedOrganizationLimits.landscapes
  }

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get dropdownHeight () {
    return this.MAX_LANDSCAPE_VISIBLE_LINES * 32
  }

  get selectedOrganization () {
    return this.organizationModule.organizations
      .map(o => ({
        ...o,
        permission: this.organizationModule.organizationPermission(o)
      }))
      .find(o => o.id === this.selectedOrganizationId)
  }

  get selectedOrganizationPermission () {
    return this.organizationModule.organizationPermission(this.selectedOrganization)
  }

  get authorizedToEdit () {
    return this.selectedOrganizationPermission === 'admin' || this.selectedOrganizationPermission === 'write'
  }

  async mounted () {
    const authorization = await this.userModule.getAuthorizationHeader()

    this.landscapes = this.landscapeModule.landscapesListStatus.successInfo.organizationId === this.selectedOrganizationId
      ? [...this.landscapeModule.landscapes]
      : (await LandscapesService.landscapesList(authorization, this.selectedOrganizationId)).landscapes

    this.loading = false
  }

  createLandscape () {
    if (!this.landscapeLimitReached && this.selectedOrganizationPermission === 'admin') {
      this.$router.push({
        name: 'landscape-setup',
        query: {
          organization: this.selectedOrganization?.id
        }
      })
    }
  }

  setLandscape (landscapeId: string) {
    if (this.currentLandscapeId !== landscapeId) {
      this.$router.push({
        name: 'landscape',
        params: {
          landscapeId,
          versionId: this.currentLandscapeId === landscapeId ? this.currentVersionId : 'latest'
        }
      })

      this.routeModule.resetHistory()
    }

    this.$emit('close')
  }

  goToLandscapeDocs () {
    window.open('https://docs.icepanel.io/features/landscape', '_blank')
  }

  goToManageOrganization () {
    this.$emit('close')
    this.$router.push({
      name: 'organization-manage-overview',
      params: {
        organizationId: this.selectedOrganizationId
      }
    })
  }
}
