import { formatRelative } from 'date-fns'

export const dateRelative = (value: string | number | Date | undefined) => {
  if (!value) {
    return ''
  }
  if (typeof value === 'string') {
    value = new Date(value)
  }
  return formatRelative(value, new Date())
}
