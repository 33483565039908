
import { Landscape, Organization } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import ContextMenuContent from '@/components/context-menu/content.vue'
import ContextMenu from '@/components/context-menu/index.vue'
import ContextMenuItem from '@/components/context-menu/item.vue'
import { AlertModule } from '@/modules/alert/store'
import { IOrganizationLimits } from '@/modules/organization/helpers/limits'

import * as analytics from '../../helpers/analytics'
import { LandscapeModule } from '../../store'

@Component({
  components: {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem
  },
  name: 'LandscapeObjectListItem'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)

  @Prop() readonly authorizedToEdit!: boolean
  @Prop() readonly landscape!: Landscape
  @Prop({ default: false }) readonly landscapeLimitReached!: Boolean
  @Prop() readonly selectedOrganizationLimits!: IOrganizationLimits
  @Prop() readonly organization!: Organization

  @Ref() readonly menuRef!: ContextMenu

  editName = false
  editNameModel = ''
  editNameFocused = false

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get selectedOrganizationLimitsDomains () {
    return this.selectedOrganizationLimits.domains
  }

  get menuItems () {
    return [
      {
        action: () => {
          this.editNameModel = this.landscape.name
          this.editName = true
          this.menuRef.close()
        },
        icon: '$fas-edit',
        id: 'rename-landscape',
        name: 'Rename landscape'
      },
      {
        action: () => this.duplicateLandscape(),
        disabled: this.landscapeLimitReached,
        disabledMessage: 'Landscape limit reached',
        icon: '$custom-duotone-clone',
        id: 'duplicate',
        name: 'Duplicate landscape'
      },
      {
        action: () => this.duplicateToOrganization(),
        icon: '$custom-duotone-building-circle-arrow-right',
        id: 'duplicate-to-org',
        name: 'Duplicate to another organization'
      },
      {
        action: () => this.mergeLandscape(),
        disabled: this.selectedOrganizationLimitsDomains <= 1,
        disabledMessage: 'Organization domains limit reached',
        icon: '$custom-duotone-export',
        id: 'merge',
        name: 'Copy model/diagrams between landscapes'
      },
      {
        action: () => this.deleteLandscape(),
        icon: '$custom-duotone-trash-can',
        id: 'delete',
        name: 'Delete landscape',
        type: 'danger'
      }
    ]
  }

  async duplicateLandscape () {
    if (!this.authorizedToEdit || this.landscapeLimitReached) {
      return
    }
    this.$emit('close')

    this.$replaceQuery({
      landscapes_appbar_menu: undefined
    })

    const newLandscape = await this.landscapeModule.landscapeDuplicate({
      landscapeId: this.landscape.id,
      name: `${this.landscape.name} duplicate`,
      organizationId: this.organization.id
    })

    analytics.landscapeDuplicate.track(this, {
      landscapeId: [this.landscape.id, newLandscape.id],
      landscapeName: newLandscape.name,
      landscapeOrganizationChanged: false,
      organizationId: [this.landscape.organizationId, newLandscape.organizationId]
    })
    analytics.landscapeCreate.track(this, {
      landscapeId: [newLandscape.id],
      landscapeName: newLandscape.name,
      organizationId: [newLandscape.organizationId]
    })

    this.alertModule.pushAlert({
      color: 'success',
      message: `Duplicated ${this.landscape.name}`
    })

    this.$router.push({
      name: 'landscape',
      params: {
        landscapeId: newLandscape.id,
        organizationId: newLandscape.organizationId
      }
    })
  }

  async updateLandscape (name: string) {
    if (this.landscape.name === name) {
      return
    }

    await this.landscapeModule.landscapeUpdate({
      landscapeId: this.landscape.id,
      organizationId: this.landscape.organizationId,
      update: {
        name
      }
    })

    analytics.landscapeUpdate.track(this, {
      landscapeId: [this.landscape.id],
      landscapeName: name,
      organizationId: [this.landscape.organizationId]
    })

    this.landscape.name = name
    this.editName = false
  }

  duplicateToOrganization () {
    this.$emit('close')
    this.$pushQuery({
      landscape_duplicate_dialog: `${this.landscape.id}-${this.organization.id}`
    })
  }

  mergeLandscape () {
    this.$pushQuery({
      landscape_copy_dialog: this.landscape.id
    })
  }

  deleteLandscape () {
    this.$pushQuery({
      landscape_delete_dialog: this.landscape.id
    })
  }
}
